import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";

const AcquiringForm = ({ data, onSubmit }) => {
  const brand = useSelector((state) => state.brand.active);
  const affiliate = useSelector((state) => state.affiliate.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <div className="d-flex align-items-center mb-4">
        <h5>Настройки</h5>
        <div>
          <Badge className="ms-3">{affiliate.title ?? affiliate.full}</Badge>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <div className="mb-4">
            <Select
              label="Тип"
              placeholder="Выберите тип"
              value={form?.relationModule?.options?.type}
              data={[
                {
                  title: "Тинькофф",
                  value: "tinkoff",
                  image: "/images/modules/mini/tinkoff.webp",
                },
                {
                  title: "ЮКасса",
                  value: "yookassa",
                  image: "/images/modules/mini/yookassa.png",
                },
                {
                  title: "Альфа Банк",
                  value: "alfa",
                  image: "/images/modules/mini/alfa.webp",
                },
                {
                  title: "Сбербанк",
                  value: "sberbank",
                  image: "/images/modules/mini/sber.webp",
                },
                {
                  title: "ВТБ",
                  value: "vtb",
                  image: "/images/modules/mini/vtb.webp",
                  disabled: true,
                },
              ]}
              onClick={(e) => setValue("relationModule.options.type", e.value)}
            />
            <p className="text-muted fs-07 mt-1">
              Ваш эквайринг. Все настройки можно узнать у банка.
            </p>
          </div>
        </Col>
        {form?.relationModule?.options?.type == "alfa" && (
          <>
            <Col lg={6}>
              <div className="mb-4">
                <Select
                  label="Сервис"
                  placeholder="Выберите сервис"
                  value={form?.relationModule?.options?.service}
                  data={[
                    {
                      title: "Pay",
                      value: "pay",
                    },
                    {
                      title: "Payment",
                      value: "payment",
                    },
                  ]}
                  onClick={(e) =>
                    setValue("relationModule.options.service", e.value)
                  }
                />
                <p className="text-muted fs-07 mt-1">
                  Сервис который вы используйте, можно узнать в тех поддержке
                  банка
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="Логин"
                  placeholder="Введите логин"
                  name="relationModule.options.login"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.login}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  type="password"
                  label="Пароль"
                  name="relationModule.options.password"
                  placeholder="Необязательно"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.password}
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
          </>
        )}

        {form?.relationModule?.options?.type == "yookassa" && (
          <>
            <Col lg={6}>
              <div className="mb-3">
                <Input
                  label="Номер магазина (shopId)"
                  placeholder="Введите номер магазина"
                  name="relationModule.options.id"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.id}
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить при создании магазина shopId
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="Токен"
                  placeholder="Введите токен"
                  name="relationModule.options.login"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.login}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <p className="text-muted fs-07 mt-1">
                  Выдается при создании магазина. Например: live_12345,
                  test_12345
                </p>
              </div>
            </Col>
          </>
        )}

        {form?.relationModule?.options?.type == "tinkoff" && (
          <>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="TerminalKey/Логин"
                  placeholder="Введите ключ терминала"
                  name="relationModule.options.login"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.login}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  type="password"
                  label="Пароль"
                  name="relationModule.options.password"
                  placeholder="Введите пароль"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.password}
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
          </>
        )}
        {form?.relationModule?.options?.type == "sberbank" && (
          <>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="Логин"
                  placeholder="Введите логин"
                  name="relationModule.options.login"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.login}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  type="password"
                  label="Пароль"
                  name="relationModule.options.password"
                  placeholder="Введите пароль"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.password}
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Можно получить в письме от банка
                </p>
              </div>
            </Col>
          </>
        )}
        <h5 className="mb-4">Переход после оплаты для сайта</h5>
        <Row>
          {form?.relationModule?.options?.type == "yookassa" && (
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="Страница после оплаты"
                  placeholder="Введите ссылку"
                  name="relationModule.options.site.success"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.site?.success ??
                    `https://lk.yooapp.ru/redirect/${brand.id}/success`
                  }
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Ссылка для перехода после оплаты
                </p>
              </div>
            </Col>
          )}
          {form?.relationModule?.options?.type != "yookassa" && (
            <>
              <Col lg={6}>
                <div className="mb-4">
                  <Input
                    label="Страница успешной оплаты"
                    placeholder="Введите ссылку"
                    name="relationModule.options.site.success"
                    errors={errors}
                    defaultValue={
                      form?.relationModule?.options?.site?.success ??
                      `https://lk.yooapp.ru/redirect/${brand.id}/success`
                    }
                    register={register}
                  />
                  <p className="text-muted fs-07 mt-1">
                    Ссылка для перехода после успешной оплаты
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  <Input
                    label="Страница при ошибке"
                    placeholder="Введите ссылку"
                    name="relationModule.options.site.error"
                    errors={errors}
                    defaultValue={
                      form?.relationModule?.options?.site?.error ??
                      `https://lk.yooapp.ru/redirect/${brand.id}/error`
                    }
                    register={register}
                  />
                  <p className="text-muted fs-07 mt-1">
                    Ссылка для перехода при ошибке
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
        <h5 className="mb-4">Переход после оплаты в приложение</h5>
        <Row>
          {form?.relationModule?.options?.type == "yookassa" && (
            <Col lg={6}>
              <div className="mb-4">
                <Input
                  label="Ссылка после оплаты"
                  placeholder="Введите ссылку"
                  name="relationModule.options.app.success"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.app?.success}
                  register={register}
                />
                <p className="text-muted fs-07 mt-1">
                  Ссылка для перехода после оплаты
                </p>
              </div>
            </Col>
          )}
          {form?.relationModule?.options?.type != "yookassa" && (
            <>
              <Col lg={6}>
                <div className="mb-4">
                  <Input
                    label="Ссылка успешной оплаты"
                    placeholder="Введите ссылку"
                    name="relationModule.options.app.success"
                    errors={errors}
                    defaultValue={form?.relationModule?.options?.app?.success}
                    register={register}
                  />
                  <p className="text-muted fs-07 mt-1">
                    Ссылка для перехода после успешной оплаты
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  <Input
                    label="Ссылка при ошибке"
                    placeholder="Введите ссылку"
                    name="relationModule.options.app.error"
                    errors={errors}
                    defaultValue={form?.relationModule?.options?.app?.error}
                    register={register}
                  />
                  <p className="text-muted fs-07 mt-1">
                    Ссылка для перехода при ошибке
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
        <hr />
        <Col lg={12}>
          <div className="mb-4 mt-3">
            <Input
              label="Ссылка для уведомлений (callback)"
              readOnly={false}
              defaultValue={`${process.env.REACT_APP_WEBHOOK_URL}/pay/${
                brand?.id ?? ""
              }/`}
            />
            <p className="text-muted fs-07 mt-1">
              Данную ссылку нужно указать в настройках вашего банка, куда будут
              приходить уведомления о проведенных платежах.
            </p>
          </div>
        </Col>
        {/* {form?.relationModule?.options?.error && (
          <>
            <hr />
            <h5>История ошибок</h5>
            <div className="mb-4 mt-3">
              {typeof form.relationModule.options.error === "object"
                ? JSON.parse(form.relationModule.options.error)
                : String(form.relationModule.options.error)}
            </div>
          </>
        )} */}
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default AcquiringForm;
