import { languageCode } from "./all";

// value - цена, currency - выводить валюту (true|false))
const customPrice = (value, currency = true, langData) => {
  // Получаем выбранный язык из brandActive
  const lang = langData ? languageCode(langData) : 'ru'; // 'ru' - значение по умолчанию

  // Преобразуем значение в число
  value = parseFloat(value);

  // Создаем объект с курсами валют (замените на реальные курсы)
  const exchangeRates = {
    ru: { currency: "₽", value: 1 }, // Рубль
    en: { currency: "$", value: 97.25 }, // Доллар
    kk: { currency: "₸", value: 0.18 }, // Тенге
  };

  // Получаем курс валюты из exchangeRates
  const exchangeRate = exchangeRates[lang];

  // Преобразуем значение в выбранную валюту
  value = value.toFixed(2); // Ограничиваем до 2-х знаков после запятой

  // Убираем нули после запятой, если это возможно
  value = parseFloat(value).toString();

  // Форматируем значение с валютным символом
  if (typeof currency === "string") {
    value = value + "\u00A0" + currency;
  } else {
    value = value + "\u00A0" + (exchangeRate?.currency || "₽");
  }

  return value;
};
const convertWeight = (value) => {
  // Проверка на falsy значения (null, undefined, 0, "", NaN)
  if (!value) {
    return "0\u00A0г";
  }

  // Преобразование в число один раз
  const weight = parseFloat(value);

  // Константы для лучшей читаемости
  const GRAM_LIMIT = 1000;
  const KILOGRAM_LIMIT = 1000 * GRAM_LIMIT;

  // Логика конвертации
  let convertedWeight, unit;
  if (weight >= KILOGRAM_LIMIT) {
    convertedWeight = weight / KILOGRAM_LIMIT;
    unit = "т";
  } else if (weight >= GRAM_LIMIT) {
    convertedWeight = weight / GRAM_LIMIT;
    unit = "кг";
  } else {
    convertedWeight = weight;
    unit = "г";
  }

  // Форматирование
  return `${convertedWeight.toLocaleString()}\u00A0${unit}`;
};
const kkal = (data) => {
  let totalProtein = 0;
  let totalFat = 0;
  let totalCarbohydrate = 0;
  let totalWeight = 0;

  const PROTEIN_CALORIES_PER_GRAM = 4;
  const FAT_CALORIES_PER_GRAM = 9;
  const CARBOHYDRATE_CALORIES_PER_GRAM = 4;

  if (data?.length > 0) {
    data.forEach((item) => {
      const energy = item?.ingredient?.energy;

      totalFat += FAT_CALORIES_PER_GRAM * (Number(energy?.fat) || 0);
      totalCarbohydrate +=
        CARBOHYDRATE_CALORIES_PER_GRAM * (Number(energy?.carbohydrate) || 0);
      totalProtein +=
        PROTEIN_CALORIES_PER_GRAM * (Number(energy?.protein) || 0);
      totalWeight += Number(item.weight) || 0;
    });
  }

  const totalCaloriesPer100g = totalFat + totalCarbohydrate + totalProtein;
  const totalCalories = Math.round(totalWeight * (totalCaloriesPer100g / 100));

  return {
    kkal: Math.round(totalCaloriesPer100g),
    protein: Math.round(totalProtein),
    fat: Math.round(totalFat),
    carbohydrate: Math.round(totalCarbohydrate),
    weight: Math.round(totalWeight),
    kkalAll: totalCalories,
  };
};

const weightFoodTypes = [
  { title: "мл", value: "мл" },
  { title: "л", value: "л" },
  { title: "г", value: "г" },
  { title: "кг", value: "кг" },
  { title: "т", value: "т" },
  { title: "шт", value: "шт" },
];

const weightShopTypes = [
  { title: "мл", value: "мл" },
  { title: "л", value: "л" },
  { title: "г", value: "г" },
  { title: "кг", value: "кг" },
  { title: "т", value: "т" },
  { title: "шт", value: "шт" },
  { title: "м", value: "м" },
  { title: "см", value: "см" },
  { title: "мм", value: "мм" },
  { title: "м2", value: "м2" },
  { title: "м3", value: "м3" },
];

export { customPrice, kkal, weightFoodTypes, weightShopTypes, convertWeight };
