import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Accordion, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  IoChevronBackOutline,
  IoCloseOutline,
  IoCreateOutline,
  IoImageOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Tags from "../../components/Tags";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { localeData, tagsData } from "../../helpers/all";
import {
  convertWeight,
  customPrice,
  kkal,
  weightFoodTypes,
  weightShopTypes,
} from "../../helpers/product";
import { getAdditions } from "../../services/addition";
import { getIngredients } from "../../services/ingredient";
import { getModifiers } from "../../services/modifier";
import {
  // addProductParam,
  // deleteProductParam,
  editProduct,
  getProduct,
} from "../../services/product";
import { getWishes } from "../../services/wish";
import { getImageURL } from "../../helpers/image";

const ProductEdit = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const brand = useSelector((state) => state?.brand?.active);
  const sync = !!brand?.options?.type;

  const [listModifier, setListModifier] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [listWish, setListWish] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalWishDelete, setModalWishDelete] = useState({
    show: false,
    id: false,
  });
  const [modalModifierDelete, setModalModifierDelete] = useState({
    show: false,
    id: false,
  });
  const [listAddition, setListAddition] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalAdditionDelete, setModalAdditionDelete] = useState({
    show: false,
    id: false,
  });
  const [editIngredient, setEditIngredient] = useState({
    show: false,
    data: {},
  });
  const [listStorage, setListIngredient] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalIngredientDelete, setModalIngredientDelete] = useState({
    show: false,
    id: false,
  });
  const [showModalTags, setShowModalTags] = useState(false);
  const [searchModifier, setSearchModifier] = useState({
    text: null,
    data: [],
  });
  const [loading, setLoading] = useState(true);

  const modifierColumns = [
    {
      name: "Название",
      selector: "title",
      size: 6,
      cell: (row) => (
        <div>
          {row?.title}
          {row.options?.titleOld && (
            <p className="text-muted fs-08">{row.options?.titleOld}</p>
          )}
        </div>
      ),
    },
    {
      name: "Категория",
      selector: "category.title",
      size: 3,
      cell: (row) => row?.category?.title ?? "-",
    },
    // {
    //   name: "Описание",
    //   selector: "description",
    //   size: 3,
    //   cell: (row) => (
    //     <div className="text-transfer w-100">{row?.description ?? "-"}</div>
    //   ),
    // },
    {
      size: 2,
      name: "Цена",
      align: "right",
      selector: "price",
      cell: (row) => customPrice(row.price),
    },
  ];
  const additionColumns = [
    {
      name: "Название",
      selector: "title",
    },
  ];
  const ingredientColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      name: "БЖУ",
      cell: (row) => (
        <span>
          {row.energy.protein}
          <sup>Б</sup> {row.energy.fat}
          <sup>Ж</sup> {row.energy.carbohydrate}
          <sup>У</sup>
        </span>
      ),
    },
  ];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      energy: {
        weight: 0,
        weightType: "г",
      },
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useEffect(() => {
    if (listModifier.show) {
      if (!listModifier?.items?.length) {
        setListModifier((e) => ({
          ...e,
          loading: true,
        }));
      }
      getModifiers({ size: 250 })
        .then((res) =>
          setListModifier((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListModifier((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listModifier.show]);

  useEffect(() => {
    if (listAddition.show) {
      if (!listAddition?.items?.length) {
        setListAddition((e) => ({
          ...e,
          loading: true,
        }));
      }
      getAdditions({ size: 250 })
        .then((res) =>
          setListAddition((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListAddition((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listAddition.show]);

  useEffect(() => {
    if (listWish.show) {
      if (!listWish?.items?.length) {
        setListWish((e) => ({
          ...e,
          loading: true,
        }));
      }
      getWishes({ size: 250 })
        .then((res) =>
          setListWish((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListWish((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listWish.show]);

  useEffect(() => {
    if (listStorage.show) {
      if (!listStorage?.items?.length) {
        setListIngredient((e) => ({
          ...e,
          loading: true,
        }));
      }
      getIngredients({ size: 250 })
        .then((res) =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listStorage.show]);

  const getPage = useCallback(() => {
    getProduct(productId)
      .then((res) => {
        if (res) {
          reset(res);
          kkalData.current =
            res?.storages?.length > 0 ? kkal(res.storages) : res?.energy;

          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, [productId, kkalData]);

  const onSearchModifier = useCallback(() => {
    const lowerCaseQuery = searchModifier?.text
      ? searchModifier.text.toLowerCase()
      : null;

    const results =
      listModifier?.items?.length > 0
        ? listModifier.items.filter((object) => {
            const lowerCaseTitle = object?.title
              ? object.title.toLowerCase()
              : null;
            const lowerCaseDescription = object?.description
              ? object.description.toLowerCase()
              : null;
            const lowerCaseTitleOld = object?.options?.titleOld
              ? object.options.titleOld.toLowerCase()
              : null;

            if (
              lowerCaseTitle?.length > 0 &&
              lowerCaseTitle.includes(lowerCaseQuery)
            ) {
              return lowerCaseTitle.includes(lowerCaseQuery);
            } else if (
              lowerCaseDescription?.length > 0 &&
              lowerCaseDescription.includes(lowerCaseQuery)
            ) {
              return lowerCaseDescription.includes(lowerCaseQuery);
            } else if (
              lowerCaseTitleOld?.length > 0 &&
              lowerCaseTitleOld.includes(lowerCaseQuery)
            ) {
              return lowerCaseTitleOld.includes(lowerCaseQuery);
            } else {
              return false;
            }
          })
        : [];

    setSearchModifier((prev) => ({
      ...prev,
      data: results,
    }));
  }, [searchModifier, listModifier]);

  useLayoutEffect(() => {
    getPage();
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editProduct(formData)
      .then(() => {
        NotificationManager.success(t("Товар успешно обновлен"));
        getPage();
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  // const onAddParam = useCallback((data) => {
  //   addProductParam(data)
  //     .then(() => {
  //       NotificationManager.success(t("Параметр успешно добавлен"));
  //       resetParam();
  //       getPage();
  //     })
  //     .catch((error) => {
  //       NotificationManager.error(
  //         typeof error?.response?.data?.error == "string"
  //           ? error.response.data.error
  //           : "Неизвестная ошибка"
  //       );
  //     });
  // }, []);

  // const onDeleteParam = useCallback((id) => {
  //   deleteProductParam(id)
  //     .then(() => {
  //       NotificationManager.success(t("Параметр успешно удален"));
  //       getPage();
  //     })
  //     .catch((error) => {
  //       NotificationManager.error(
  //         typeof error?.response?.data?.error == "string"
  //           ? error.response.data.error
  //           : "Неизвестная ошибка"
  //       );
  //     });
  // }, []);

  // const FormModifier = ({ data, onChange }) => {
  //   return (
  //     <Row>
  //       <Col md={3}>
  //         <div className="mb-3 upload-box modifier d-flex flex-column align-items-center justify-content-center">
  //           <IoImageOutline size={25} className="text-muted" />
  //         </div>
  //       </Col>
  //       <Col md={9}>
  //         <Row>
  //           <Col md={12}>
  //             <div className="mb-3">
  //               <Input
  //                 defaultValue={data.title ?? ""}
  //                 label="Название"
  //                 name="title"
  //                 onChange={(e) => onChange && onChange("title", e)}
  //               />
  //             </div>
  //           </Col>
  //           <Col md={6}>
  //             <div className="mb-3">
  //               <Input
  //                 defaultValue={data.weight ?? ""}
  //                 label="Вес, г"
  //                 name="weight"
  //                 type="number"
  //                 onChange={(e) => onChange && onChange("weight", e)}
  //               />
  //             </div>
  //           </Col>
  //           <Col md={6}>
  //             <div className="mb-3">
  //               <Input
  //                 defaultValue={data.price ?? ""}
  //                 label="Цена"
  //                 type="number"
  //                 name="price"
  //                 onChange={(e) => onChange && onChange("price", e)}
  //               />
  //             </div>
  //           </Col>
  //         </Row>
  //       </Col>
  //       <Col md={12}>
  //         <div className="mb-3">
  //           <Textarea
  //             defaultValue={data.description ?? ""}
  //             label="Описание"
  //             name="description"
  //             rows={4}
  //             onChange={(e) => onChange && onChange("description", e)}
  //           />
  //           <p className="text-muted fs-08 mt-2">
  //             Если у вас есть интеграция с системой учета, желательно не
  //             редактировать цены модификаторов.
  //           </p>
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // };

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const header = useMemo(() => {
    return (
      <>
        <Row>
          <Col md={12}>
            <Input
              placeholder={t("Найти")}
              className="w-100"
              onChange={(e) => {
                setSearchModifier((prev) => ({ ...prev, text: e }));
                onSearchModifier();
              }}
              rightIcon={() => <IoSearchOutline size={22} />}
              rightIconClick={() => onSearchModifier()}
              onKeyDown={(e) => e === "Enter" && onSearchModifier()}
            />
          </Col>
          {/* <Col md={4}>
            <Select
              classNameContainer="w-100"
              label="Категория"
              data={
                data?.categories?.items?.length > 0
                  ? data.categories.items.map((e) => ({
                      title: e.title,
                      value: e.id,
                    }))
                  : []
              }
            />
          </Col> */}
        </Row>
      </>
    );
  }, [data?.categories?.items, searchModifier, t]);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого товара нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={t(data.title ?? "Товар")} />
      <div>
        <Link
          to="/catalog/products"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} />{" "}
          {t("Назад к списку")}
        </Link>
      </div>
      <h3 className="mb-4">{t("Редактирование товара")}</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      label="Подзаголовок"
                      name="options.subtitle"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      label="Состав"
                      name="options.сompound"
                      errors={errors}
                      rows={2}
                      register={register}
                    />
                  </div>
                </Col>
                {data?.categories?.items?.length > 0 && (
                  <Col lg={6}>
                    <div className="mb-3">
                      <Select
                        label="Категория"
                        search
                        value={data.categoryId}
                        data={data.categories.items.map((e) => ({
                          title: e.title,
                          value: e.id,
                        }))}
                        onClick={(e) => setValue("categoryId", e.value)}
                      />
                    </div>
                  </Col>
                )}
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Тип"
                      onClick={(e) => setValue("type", e.value)}
                      value={
                        data?.type
                          ? data.type
                          : brand?.type == "food"
                          ? "dish"
                          : "product"
                      }
                      data={
                        brand?.type == "food"
                          ? [
                              { title: "Блюдо", value: "dish" },
                              { title: "Подарок", value: "gift" },
                            ]
                          : [
                              { title: "Товар", value: "product" },
                              { title: "Проект", value: "project" },
                              { title: "Услуга", value: "service" },
                              { title: "Подарок", value: "gift" },
                            ]
                      }
                    />
                  </div>
                </Col>

                {brand?.type == "food" && (
                  <>
                    <Col md={6}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data.options?.person ?? 0}
                          label="Кол-во приборов"
                          name="options.person"
                          type="number"
                          max={100}
                          min={0}
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                  </>
                )}
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.home}
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <p className="fw-7 fs-09 mb-1">Теги</p>
                  <div className="d-inline-flex flex-wrap align-items-center">
                    {data?.tags?.length > 0 && <Tags data={data.tags} />}
                    <Button
                      className="btn-primary-outline"
                      onClick={() => setShowModalTags(true)}
                    >
                      Изменить
                    </Button>
                  </div>
                  <CustomModal
                    size="sm"
                    title="Теги"
                    show={showModalTags}
                    setShow={(e) => setShowModalTags(e)}
                  >
                    {tagsData?.length > 0 &&
                      tagsData.map((e) => {
                        let array =
                          data?.tags?.length > 0 ? [...data.tags] : [];
                        let is =
                          array?.length > 0
                            ? array.findIndex((item) => item === e.name)
                            : -1;
                        let newArray =
                          is != -1
                            ? array.filter((item) => item != e.name)
                            : [...array, e.name];

                        return (
                          <a
                            onClick={() => setValue("tags", newArray)}
                            className={"tag-edit" + (is != -1 ? " active" : "")}
                          >
                            <img src={e.value} />
                          </a>
                        );
                      })}
                  </CustomModal>
                </Col>
                {data?.type == "gift" && (
                  <>
                    <Col md={6}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data?.options?.minCart ?? 0}
                          label="Минимальная сумма корзины"
                          name="options.minCart"
                          type="number"
                          max={1000000}
                          min={0}
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                  </>
                )}
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>

            <Tabs className="mb-3" fill>
              {/* {brand.type == "food" && (
                <Tab.Pane eventKey={1} title="Состав" className="px-4 pb-4">
                  {data?.storages?.length > 0 ? (
                    <>
                      <div className="d-flex">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to={"/catalog/modifiers/create"}
                        >
                          Создать
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListIngredient((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                      <Accordion defaultActiveKey="0" className="mt-3">
                        {data.storages.map((e, index) => (
                          <Accordion.Item eventKey={index}>
                            <div className="d-flex align-items-center">
                              <Accordion.Header className="w-100">
                                {e?.title ??
                                  e?.ingredient?.title ??
                                  "Нет названия продукта"}
                              </Accordion.Header>
                              <a
                                className="mx-2"
                                onClick={() =>
                                  setEditIngredient((info) => ({
                                    show: !info.show,
                                    data: e,
                                  }))
                                }
                              >
                                <IoCreateOutline
                                  size={22}
                                  className="text-muted"
                                />
                              </a>
                              <a
                                className="mx-2"
                                onClick={() =>
                                  setModalIngredientDelete({
                                    show: !modalIngredientDelete.show,
                                    id: e.id,
                                  })
                                }
                              >
                                <IoTrashOutline
                                  size={20}
                                  className="text-danger"
                                />
                              </a>
                            </div>
                            <Accordion.Body>
                              <Row>
                                <Col md="auto">
                                  <img
                                    src="/images/empty-product-image.png"
                                    width={40}
                                    height={40}
                                  />
                                </Col>
                                <Col>
                                  <p className="text-muted fs-09">Описание</p>
                                  {e?.desc ??
                                    e?.ingredient?.desc ??
                                    "Описания нет"}
                                </Col>
                                <Col
                                  md="auto"
                                  className="d-flex flex-column align-items-end"
                                >
                                  <p className="text-muted fs-09">Цена</p>
                                  {customPrice(e.price)}
                                </Col>
                                <Col
                                  md="auto"
                                  className="d-flex flex-column align-items-end"
                                >
                                  <p className="text-muted fs-09">Вес</p>
                                  {convertWeight(e.weight ?? 0)}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </>
                  ) : (
                    <p className="my-5 text-muted text-center">
                      Состав отсутствует
                      <div className="mt-3 d-flex justify-content-center">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/ingredient/create"
                        >
                          Создать
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListIngredient((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                    </p>
                  )}
                </Tab.Pane>
              )} */}
              {brand.type == "food" && (
                <Tab.Pane
                  eventKey={2}
                  title={t("Добавки")}
                  className="px-4 pb-4"
                >
                  {data?.additions?.length > 0 ? (
                    <>
                      <div className="d-flex">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/catalog/additions/create"
                        >
                          {t("Создать")}
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListAddition((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                      <Accordion defaultActiveKey="0" className="mt-3">
                        {data.additions.map((e, index) => (
                          <Accordion.Item eventKey={index}>
                            <div className="d-flex align-items-center">
                              <Accordion.Header className="w-100">
                                {e?.title ?? e?.addition?.title ?? ""}
                              </Accordion.Header>
                              <a
                                className="mx-2"
                                // onClick={() =>
                                //   setEditAddition((info) => ({
                                //     show: !info.show,
                                //     data: e,
                                //   }))
                                // }
                              >
                                <IoCreateOutline
                                  size={22}
                                  className="text-muted"
                                />
                              </a>
                              <a
                                className="mx-2"
                                onClick={() =>
                                  setModalAdditionDelete({
                                    show: !modalAdditionDelete.show,
                                    id: e.id,
                                  })
                                }
                              >
                                <IoTrashOutline
                                  size={20}
                                  className="text-danger"
                                />
                              </a>
                            </div>
                            <Accordion.Body>
                              <Row className="mb-2">
                                <Col md="auto">
                                  <img
                                    src="/images/empty-product-image.png"
                                    width={40}
                                    height={40}
                                  />
                                </Col>
                                <Col>
                                  <p>{e?.title ?? "Нет названия"}</p>
                                  <p className="text-muted fs-09">
                                    {e?.description ?? "Описания нет"}
                                  </p>
                                </Col>
                                <Col
                                  md="auto"
                                  className="d-flex flex-column align-items-end"
                                >
                                  <p className="text-muted fs-09">Цена</p>
                                  {customPrice(e?.price)}
                                </Col>
                                <Col
                                  md="auto"
                                  className="d-flex flex-column align-items-end"
                                >
                                  <p className="text-muted fs-09">Вес</p>
                                  {convertWeight(e?.energy?.weight ?? 0)}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </>
                  ) : (
                    <p className="my-5 text-muted text-center">
                      {t("Добавок нет")}
                      <div className="mt-3 d-flex justify-content-center">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/catalog/additions/create"
                        >
                          {t("Добавить добавку")}
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListAddition((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                    </p>
                  )}
                </Tab.Pane>
              )}

              <Tab.Pane
                eventKey={3}
                title={t("Модификаторы")}
                className="px-4 pb-4"
              >
                {data?.modifiers?.length > 0 ? (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/catalog/modifiers/create"
                        >
                          {t("Создать")}
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListModifier((e) => ({ ...e, show: true }))
                          }
                        >
                          {t("Выбрать из списка")}
                        </Button>
                      </div>
                      <div>
                        <Form.Check>
                          <Form.Check.Input
                            type="checkbox"
                            id="options-modifier-price-sum"
                            defaultChecked={
                              data?.options?.modifierPriceSum ?? false
                            }
                            {...register("options.modifierPriceSum")}
                          />
                          <Form.Check.Label
                            htmlFor="options-modifier-price-sum"
                            className="ms-2 fs-09"
                          >
                            {t("Суммируется с ценой товара?")}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    </div>
                    {data?.modifiers?.length > 0 && (
                      <Accordion defaultActiveKey={0} className="mt-3">
                        {data.modifiers
                          .sort((a, b) => a.price - b.price)
                          .map((e, index1) => (
                            <Accordion.Item key={index1} eventKey={index1}>
                              <div className="d-flex align-items-center">
                                {/* <Form.Check>
                                    <Form.Check.Input
                                      type="radio"
                                      name="main-modifier"
                                      id={"options-modifier-" + index1}
                                      defaultChecked={e.main}
                                      onChange={(value) =>
                                        setValue(
                                          "modifiers",
                                          data.modifiers.map((mod, index2) => {
                                            mod.main = false;
                                            if (index1 === index2) {
                                              mod = e;
                                              mod.main = value.target.checked;
                                            }
                                            return mod;
                                          })
                                        )
                                      }
                                    />
                                  </Form.Check> */}
                                <Accordion.Header className="w-100  flex-1 d-flex align-items-center">
                                  {e?.options?.titleOld
                                    ? e.options.titleOld + " "
                                    : null}
                                  {e?.title}
                                </Accordion.Header>
                                <Link
                                  className="mx-2"
                                  to={"/catalog/modifier/" + e.id}
                                >
                                  <IoCreateOutline
                                    size={22}
                                    className="text-muted"
                                  />
                                </Link>
                                <a
                                  className="mx-2"
                                  onClick={() =>
                                    setModalModifierDelete({
                                      show: !modalModifierDelete.show,
                                      id: e.id,
                                    })
                                  }
                                >
                                  <IoTrashOutline
                                    size={20}
                                    className="text-danger"
                                  />
                                </a>
                              </div>
                              <Accordion.Body>
                                <Row>
                                  {e?.medias?.length > 0 &&
                                    e?.medias[0]?.media && (
                                      <Col md="auto">
                                        <img
                                          src={getImageURL({
                                            path: e.medias[0].media,
                                            type: "modifier",
                                          })}
                                          width={40}
                                          height={40}
                                        />
                                      </Col>
                                    )}
                                  <Col>
                                    <p className="text-muted fs-08">
                                      {t("Описание")}
                                    </p>
                                    <div className="text-transfer fs-09 mb-2">
                                      {e?.description ?? "Описания нет"}
                                    </div>
                                  </Col>
                                  <Col md="auto" className="d-flex flex-column">
                                    <p className="text-muted fs-08">
                                      {t("Цена")}
                                    </p>
                                    <div className="fs-09">
                                      {customPrice(e.price)}
                                    </div>
                                  </Col>
                                  <Col md="auto" className="d-flex flex-column">
                                    <p className="text-muted fs-08">
                                      {t("Вес")}
                                    </p>
                                    <div className="fs-09">
                                      {convertWeight(e?.energy?.weight ?? 0)}
                                    </div>
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                      </Accordion>
                    )}
                  </>
                ) : (
                  <p className="my-5 text-muted text-center">
                    {t("Модификаторов нет")}
                    <div className="mt-3 d-flex justify-content-center">
                      <Link
                        disabled={sync}
                        className="btn-primary me-3"
                        to="/catalog/modifiers/create"
                      >
                        {t("Добавить модификатор")}
                      </Link>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                  </p>
                )}
              </Tab.Pane>

              {brand?.type == "food" && (
                <Tab.Pane
                  eventKey={4}
                  title={t("Пожелания")}
                  className="px-4 pb-4"
                >
                  {data?.wishes?.length > 0 ? (
                    <>
                      <div className="d-flex align-items-center">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/catalog/wishes/create"
                        >
                          {t("Создать")}
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListWish((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                      {data?.wishes?.length > 0 && (
                        <Accordion defaultActiveKey={0} className="mt-3">
                          {data.wishes.map((e, index) => (
                            <Accordion.Item key={index} eventKey={index}>
                              <div className="d-flex align-items-center">
                                <Accordion.Header className="w-100 flex-1 d-flex align-items-center">
                                  {e?.title}
                                </Accordion.Header>
                                <Link
                                  className="mx-2"
                                  to={"/catalog/wish/" + e.id}
                                >
                                  <IoCreateOutline
                                    size={22}
                                    className="text-muted"
                                  />
                                </Link>
                                <a
                                  className="mx-2"
                                  onClick={() =>
                                    setModalWishDelete({
                                      show: !modalWishDelete.show,
                                      id: e.id,
                                    })
                                  }
                                >
                                  <IoTrashOutline
                                    size={20}
                                    className="text-danger"
                                  />
                                </a>
                              </div>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </>
                  ) : (
                    <p className="my-5 text-muted text-center">
                      {t("Пожеланий нет")}
                      <div className="mt-3 d-flex justify-content-center">
                        <Link
                          disabled={sync}
                          className="btn-primary me-3"
                          to="/catalog/wishes/create"
                        >
                          {t("Добавить пожелание")}
                        </Link>
                        <Button
                          className="btn-primary-outline"
                          onClick={() =>
                            setListWish((e) => ({ ...e, show: true }))
                          }
                        >
                          Выбрать из списка
                        </Button>
                      </div>
                    </p>
                  )}
                </Tab.Pane>
              )}
              <Tab.Pane eventKey={5} title={t("Филиалы")} className="px-4 pb-4">
                {data?.affiliates?.items?.length > 0 &&
                  data.affiliates.items.map((e) => {
                    let option =
                      data?.productOptions?.length > 0 &&
                      data.productOptions.find(
                        (item) => item.affiliateId === e.id
                      );
                    let optionIndex =
                      data?.productOptions?.length > 0 &&
                      data.productOptions.findIndex(
                        (item) => item.affiliateId === e.id
                      );

                    return (
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <Form.Check>
                            <Form.Check.Input
                              type="checkbox"
                              name="productOptions"
                              checked={option?.id}
                              onChange={() => {
                                if (option?.id) {
                                  let newArray = data.productOptions.filter(
                                    (item) => item.affiliateId != e.id
                                  );
                                  setValue("productOptions", newArray);
                                } else {
                                  let newArray =
                                    data?.productOptions?.length > 0
                                      ? [
                                          ...data.productOptions,
                                          {
                                            affiliateId: e.id,
                                            price: option?.price
                                              ? option.price
                                              : data.price,
                                          },
                                        ]
                                      : [
                                          {
                                            affiliateId: e.id,
                                            price: option?.price
                                              ? option.price
                                              : data.price,
                                          },
                                        ];
                                  setValue("productOptions", newArray);
                                }
                              }}
                            />
                            <Form.Check.Label className="ms-2 fs-09">
                              {e?.title ? e.title : e.full}
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <Input
                            label="Цена"
                            className="input-mini"
                            defaultValue={
                              option?.price ? option.price : data.price
                            }
                            readOnly={!!option?.affiliateId}
                            name={"optionPrice" + e.id}
                            min={0}
                            type="number"
                            onChange={(value) => {
                              if (
                                optionIndex != -1 &&
                                data?.productOptions?.length > 0
                              ) {
                                data.productOptions[optionIndex].price = value;
                                setValue("productOptions", data.productOptions);
                              }
                            }}
                          />
                          <Input
                            label="Скидка"
                            className="ms-2 input-mini"
                            defaultValue={
                              option?.price ? option.price : data.price
                            }
                            readOnly={!!option?.affiliateId}
                            name={"optionPrice" + e.id}
                            min={0}
                            type="number"
                            onChange={(value) => {
                              if (
                                optionIndex != -1 &&
                                data?.productOptions?.length > 0
                              ) {
                                data.productOptions[optionIndex].discount =
                                  value;
                                setValue("productOptions", data.productOptions);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Tab.Pane>
              {brand.type == "shop" && (
                <Tab.Pane
                  eventKey={6}
                  title={t("Параметры")}
                  className="px-4 pb-4"
                >
                  <div className="mt-3 px-2">
                    <p className="mb-4 text-muted">
                      {t("Хотите добавить новые параметры?")}{" "}
                      <Link
                        className="text-success"
                        to="/catalog/params/create"
                      >
                        {t("Создать параметр")}
                      </Link>
                    </p>
                    {data?.params?.length > 0 && (
                      <Row>
                        {data?.params.map(
                          ({ param, selected }, index) =>
                            param && (
                              <Col md={6}>
                                <div className="mb-3">
                                  <Select
                                    label={param.title}
                                    search
                                    value={selected ? selected[0]?.id : null}
                                    data={
                                      param?.children?.length > 0
                                        ? param.children.map((e) => ({
                                            title: e.value,
                                            value: e.id,
                                          }))
                                        : []
                                    }
                                    onClick={(e) => {
                                      let isSelected = param.children.find(
                                        (item) => item.id === e.value
                                      );
                                      isSelected &&
                                        setValue(
                                          `params.${index}.selected.0`,
                                          isSelected
                                        );
                                    }}
                                  />
                                </div>
                              </Col>
                            )
                        )}
                      </Row>
                    )}
                  </div>
                </Tab.Pane>
              )}
              {brand.options?.lang?.length > 1 && (
                <Tab.Pane eventKey={7} title="Перевод">
                  <div className="px-4">
                    {brand.options?.lang
                      .filter((e) => e != "ru")
                      .map((lang) => {
                        let langTitle = localeData.find(
                          (e) => e.lang === lang
                        )?.title;
                        return (
                          <>
                            <div className="mb-3 fw-7">{langTitle}</div>
                            <div className="mb-3">
                              <Input
                                className="mb-3"
                                label={`Название (${langTitle})`}
                                name={`options.${lang}.title`}
                                errors={errors}
                                register={register}
                              />
                              <Textarea
                                label={`Описание (${langTitle})`}
                                name={`options.${lang}.desc`}
                                errors={errors}
                                register={register}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Tab.Pane>
              )}
            </Tabs>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3" body>
              <DragDropFile
                col={3}
                maxAspect={false}
                file={data.file}
                main
                multiple
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
              <PreviewImages
                medias={data?.medias}
                type="product"
                col={6}
                main
                onMain={(e) => setValue("main", e)}
                onDelete={(e) => {
                  setValue("medias", e.medias);
                  let newArrayDelete =
                    data?.delete?.length > 0
                      ? [...data.delete, e.delete]
                      : [e.delete];
                  setValue("delete", newArrayDelete);
                }}
              />
            </Card>
            {brand?.type == "food" ? (
              <Card body className="mb-3">
                <p className="fs-08">
                  {t("Энергетическая ценность в 100г")} -{" "}
                  <span className="text-success">
                    {kkalData?.current?.kkal ?? 0}
                  </span>{" "}
                  {t("ккал")}
                </p>
                <Row className="gx-2">
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.protein ?? 0}
                        label="Белки, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.fat ?? 0}
                        label="Жиры, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.carbohydrate ?? 0}
                        label="Углеводы, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="gx-2">
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={
                          kkalData?.current?.kkalAll ?? kkalData?.current?.kkal
                        }
                        label="Всего ккал, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        defaultValue={
                          data.energy.weight ?? kkalData?.current?.weight ?? 0
                        }
                        label="Вес, г"
                        name="energy.weight"
                        min={0}
                        type="number"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Select
                        label="Единица измерения"
                        title="г, кг, т ..."
                        value={data?.energy?.weightType}
                        data={
                          brand?.type == "food"
                            ? weightFoodTypes
                            : weightShopTypes
                        }
                        onClick={(e) => setValue("energy.weightType", e.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Card body className="mb-3">
                <Row className="gx-2">
                  <Col lg={6}>
                    <div>
                      <Input
                        defaultValue={kkalData?.current?.weight ?? 0}
                        label={"Вес, " + (data?.energy?.weightType ?? "г")}
                        name="energy.weight"
                        min={0}
                        type="number"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Select
                        label="Единица измерения"
                        title="г, кг, т ..."
                        value={data?.energy?.weightType ?? "г"}
                        data={
                          brand?.type == "food"
                            ? weightFoodTypes
                            : weightShopTypes
                        }
                        onClick={(e) => setValue("energy.weightType", e.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
            <Card body className="mb-3">
              <p className="fs-08">{t("Цена товара")}</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={data.price}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={data.discount}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                {brand.type == "shop" && (
                  <Col md={12}>
                    <Form.Check className="mt-3">
                      <Form.Check.Input
                        type="checkbox"
                        id="options-pricefrom"
                        defaultChecked={data?.options?.priceFrom ?? false}
                        {...register("options.priceFrom")}
                      />
                      <Form.Check.Label htmlFor="main" className="ms-2">
                        {t("Цена от")} {customPrice(data?.price)}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                )}
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              {t("Сохранить товар")}
            </Button>
          </div>
        </Col>
      </Row>
      <CustomModal
        title={t("Список модификаторов")}
        show={listModifier.show}
        size="lg"
        setShow={(e) => setListModifier((list) => ({ ...list, show: e }))}
        classNameBody="py-0 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListModifier((e) => ({ ...e, show: false }))}
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-primary"
              disabled={listModifier?.selected.length === 0}
              onClick={() => {
                if (listModifier?.selected.length > 0) {
                  setValue(
                    "modifiers",
                    data.modifiers.concat(
                      listModifier.selected.map((e) => e.item)
                    )
                  );
                  setListModifier((e) => ({
                    ...e,
                    items: listModifier.items.filter(
                      (e) =>
                        !listModifier.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                }
              }}
            >
              {t("Добавить выбранное")}
            </Button>
          </>
        }
      >
        {listModifier.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listModifier?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">
            {t("Пока нет модификаторов")}
          </p>
        ) : (
          <DataTable
            columns={modifierColumns}
            updateData={searchModifier.data}
            data={
              searchModifier.data?.length > 0
                ? searchModifier.data
                : listModifier.items
            }
            onChange={(items) =>
              setListModifier((e) => ({ ...e, selected: items }))
            }
            header={header}
            lite
            headClassName="light"
            selectable
          />
        )}
      </CustomModal>

      <CustomModal
        title={t("Удаление элемента")}
        show={modalModifierDelete.show}
        setShow={(e) => setModalModifierDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalModifierDelete({
                  show: !modalModifierDelete.show,
                  id: false,
                })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "modifiers",
                  data.modifiers.filter((e) => e.id != modalModifierDelete.id)
                );
                setListModifier((e) => ({
                  ...e,
                  items: [
                    ...listModifier.items,
                    data.modifiers.find((e) => e.id === modalModifierDelete.id),
                  ],
                  selected: [],
                }));
                setModalModifierDelete({ show: false, id: false });
              }}
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить модификатор из товара?")}
      </CustomModal>
      <CustomModal
        size="lg"
        title={t("Редактировать состав")}
        show={editIngredient.show}
        setShow={(e) => setEditIngredient((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setEditIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                let newArray = data.storages.map((e) =>
                  e.id === editIngredient.data.id ? editIngredient.data : e
                );
                setValue("storages", newArray);
                setEditIngredient({ show: false, data: {} });
                kkalData.current = kkal(newArray);
              }}
            >
              Сохранить продукт
            </Button>
          </>
        }
      >
        <FormIngredient
          data={editIngredient.data}
          onChange={(title, value) =>
            setEditIngredient((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        title="Список продукции"
        show={listStorage.show}
        setShow={(e) => setListIngredient((list) => ({ ...list, show: e }))}
        classNameBody="py-2 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listStorage?.selected?.length === 0}
              onClick={() => {
                if (listStorage?.selected?.length > 0) {
                  let newArray = data.storages.concat(
                    listStorage.selected.map((e) => ({
                      productId: data.id,
                      ingredientId: e.item.id,
                      ingredient: e.item,
                      weight: 0,
                      price: 0,
                    }))
                  );
                  setValue("storages", newArray);
                  setListIngredient((e) => ({
                    ...e,
                    items: listStorage.items.filter(
                      (e) =>
                        !listStorage.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                  kkalData.current = kkal(newArray);
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listStorage.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listStorage?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет продукции</p>
        ) : (
          <DataTable
            columns={ingredientColumns}
            data={listStorage.items}
            onChange={(items) =>
              setListIngredient((e) => ({ ...e, selected: items }))
            }
            lite
            selectable
          />
        )}
      </CustomModal>
      <CustomModal
        title="Удаление элемента"
        show={modalWishDelete.show}
        setShow={(e) => setModalWishDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalWishDelete({
                  show: !modalWishDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "wishes",
                  data.wishes.filter((e) => e.id != modalWishDelete.id)
                );
                setListWish((e) => ({
                  ...e,
                  items: [
                    ...listWish.items,
                    data.wishes.find((e) => e.id === modalWishDelete.id),
                  ],
                  selected: [],
                }));
                setModalWishDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пожелания?
      </CustomModal>
      <CustomModal
        title="Удаление элемента"
        show={modalIngredientDelete.show}
        setShow={(e) => setModalIngredientDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalIngredientDelete({
                  show: !modalIngredientDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "storages",
                  data.storages.filter((e) => e.id != modalIngredientDelete.id)
                );
                setListIngredient((e) => ({
                  ...e,
                  items: [
                    ...listStorage.items,
                    data.storages.find(
                      (e) => e.id === modalIngredientDelete.id
                    ),
                  ],
                  selected: [],
                }));
                setModalIngredientDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить состав из товара?
      </CustomModal>
      <CustomModal
        title="Список добавок"
        show={listAddition.show}
        setShow={(e) => setListAddition((list) => ({ ...list, show: e }))}
        classNameBody="py-0 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListAddition((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listAddition?.selected.length === 0}
              onClick={() => {
                if (listAddition?.selected.length > 0) {
                  setValue(
                    "additions",
                    data.additions.concat(
                      listAddition.selected.map((e) => e.item)
                    )
                  );
                  setListAddition((e) => ({
                    ...e,
                    items: listAddition.items.filter(
                      (e) =>
                        !listAddition.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listAddition.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listAddition?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет добавок</p>
        ) : (
          <DataTable
            columns={additionColumns}
            data={listAddition.items}
            onChange={(items) =>
              setListAddition((e) => ({ ...e, selected: items }))
            }
            lite
            headClassName="light"
            selectable
          />
        )}
      </CustomModal>
      <CustomModal
        title="Список пожеланий"
        show={listWish.show}
        setShow={(e) => setListWish((list) => ({ ...list, show: e }))}
        classNameBody="py-0 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListWish((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listWish?.selected.length === 0}
              onClick={() => {
                if (listWish?.selected.length > 0) {
                  setValue(
                    "wishes",
                    data.wishes.concat(listWish.selected.map((e) => e.item))
                  );
                  setListWish((e) => ({
                    ...e,
                    items: listWish.items.filter(
                      (e) =>
                        !listWish.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listWish.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listWish?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет пожеланий</p>
        ) : (
          <DataTable
            columns={additionColumns}
            data={listWish.items}
            onChange={(items) =>
              setListWish((e) => ({ ...e, selected: items }))
            }
            lite
            headClassName="light"
            selectable
          />
        )}
      </CustomModal>

      <CustomModal
        title="Удаление добавки"
        show={modalAdditionDelete.show}
        setShow={(e) => setModalAdditionDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalAdditionDelete({
                  show: !modalAdditionDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "additions",
                  data.additions.filter((e) => e.id != modalAdditionDelete.id)
                );
                setListAddition((e) => ({
                  ...e,
                  items: [
                    ...listAddition.items,
                    data.additions.find((e) => e.id === modalAdditionDelete.id),
                  ],
                  selected: [],
                }));
                setModalAdditionDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить модификатор из товара?
      </CustomModal>
    </>
  );
};

export default ProductEdit;
